import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { PostCardHalf, Layout, Authors, ImageHeader, TagHelperArray } from '../components/common'
import { MetaData } from '../components/common/meta'
import config from '../utils/siteConfig'
import { device } from "../utils/device.js"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import {HeaderXXL, BodyText, BodyTextSmall, H5, H3, HeaderCursive, HeaderCursiveSmall, Koenig} from "../utils/typography"
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import Prism from 'prismjs'
import "prismjs/plugins/line-numbers/prism-line-numbers.js"


/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
class Post extends React.Component {

  constructor(props){
      super(props)
      this.data = props.data
      this.location = props.location
      this.post = props.data.post
      this.prevPost = props.data.prevPost
      this.nextPost = props.data.nextPost
      this.authors = props.data.authors.edges
      this.post.html = this.post.html.replace(new RegExp("<pre><code", "g"), '<pre class="line-numbers"><code')
      this.disqusShortname = 'mwallinger-com';
      this.disqusConfig = {
          url: this.post.url,
          identifier: this.post.id,
          title: this.post.title,
      };

      this.updated = new Date(this.post.updated_at)
      this.updated = this.updated.toLocaleString('en-GB')
    }

    componentDidMount(){
      Prism.highlightAll()
    }

    render(){
      return (
              <>
                  <MetaData
                      data={this.data}
                      location={this.location}
                      type="article"
                  />
                  <Layout>
                      <ImageHeader title={this.post.title} excerpt={this.post.excerpt} image={this.post.localFeatureImage}/>

                      <CenteredContent>

                          <PostContent>
                                  {/* The main post content */ }

                              <PostStyled
                              dangerouslySetInnerHTML= {{ __html: this.post.html }}
                              />

                          </PostContent>

                          <InfoContainer>
                            <CommentNum>
                              <CommentCount config={this.disqusConfig} placeholder={'...'} />
                            </CommentNum>
                            <Time>Last Update: <time >{this.updated}</time></Time>
                          </InfoContainer>

                          <IndexContainer>
                            <IndexHeader>
                              <h2>Other.</h2>
                            </IndexHeader>
                            <TagHelperArray tags={this.post.tags}/>
                          </IndexContainer>

                          {(this.nextPost || this.prevPost) &&
                            <IndexContainer>
                              <IndexHeader>
                                <h2>Latest.</h2>
                              </IndexHeader>
                              <Container >
                              {this.nextPost &&
                                <PostCardHalf post={this.nextPost} url={this.nextPost.url} text={this.nextPost.excerpt} title={this.nextPost.title}  img={this.nextPost.localFeatureImage}/>
                              }
                              {this.prevPost &&
                                <PostCardHalf url={this.prevPost.url} text={this.prevPost.excerpt} title={this.prevPost.title}  img={this.prevPost.localFeatureImage}/>
                              }
                              </Container>
                            </IndexContainer>
                          }

                          <IndexContainer>
                            <IndexHeader>
                              <h2>Contact.</h2>
                            </IndexHeader>
                            <Authors authors={this.authors}/>
                          </IndexContainer>

                          <IndexContainer>
                            <IndexHeader>
                              <h2>Discuss.</h2>
                            </IndexHeader>
                            <DiscussContainer>
                              <Disqus config={this.disqusConfig} />
                            </DiscussContainer>
                          </IndexContainer>
                      </CenteredContent>
                  </Layout>
              </>
      )
  }
}

const InfoContainer = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

const CommentNum = styled("div")`
  margin: 0.5em 0em;
  color: ${config.textColorGrey};
  ${BodyTextSmall};
`

const Time = styled("div")`
  margin: 0.5em 0em;
  text-align: right;
  color: ${config.textColorGrey};
  ${BodyTextSmall};
`

const DiscussContainer = styled("div")`
  width: 100%;
  background-color: ${config.color2};
  padding: 2em 1em;
`

const PostStyled = styled("div")`


  p {
    margin: 2em 0;
    color: ${config.textColorGrey};
    text-align: justify;
    ${BodyText};
  }

  h1 {
    margin: 4rem 0rem 2rem 0rem;
    color: ${config.textColorDark};
    ${HeaderCursive};
  }

  h2 {
    margin: 4rem 0rem 2rem 0rem;
    color: ${config.textColorDark};
    ${HeaderCursiveSmall};
  }

  h3 {
    margin: 4rem 0rem 2rem 0rem;
    color: ${config.textColorDark};
    ${H3};
  }
  h4 {
    margin: 4rem 0rem 2rem 0rem;
    color: ${config.textColorGrey};
    ${H3};
  }
  h5 {
    margin: 4rem 0rem 2rem 0rem;
    color: ${config.textColorDark};
    ${H5};
  }
  h6 {
    margin: 4rem 0rem 2rem 0rem;
    color: ${config.textColorGrey};
    ${H5};
  }

  h7 {
    margin: 2rem 0rem;
    color: ${config.textColorGrey};
    ${BodyText};
    font-weight: 700;
  }

  blockquote{
    ${BodyText};
  }

  figcaption{
    ${BodyText};
  }

  ol {
    color: ${config.textColorGrey};
    ${BodyText};
  }

  ul {
    list-style: none; /* Remove default bullets */
    ${BodyText};
  }

  li {

    font-size: 1.0em;
  }

  ul {
    li::before {
    content: "\u2022";
    color: ${config.color2};
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    }
  }

  a{
    text-decoration: none;
    color: ${config.color2};
    ${BodyText};
    font-weight: 700;

    &:active{
      color: ${config.color3};
    }

    &:hover{
      text-decoration: underline;
    }
  }

  ${Koenig};
`

const PostContent = styled("div")`
  margin: 2em 0 5em 0;

`

const IndexContainer = styled("div")`
  margin: 10rem 0;
`

const IndexHeader = styled("div")`
  margin: -0.225em 0.2em;
  padding: 0;
  z-index: 10;
  position: relative;
  ${HeaderXXL}

  >h2{
    margin: 0;
    padding: 0;
    display: block;
    color: ${config.textColorDark};
    }
`

const CenteredContent = styled("div")`
  margin-left: auto;
  margin-right: auto;
  max-width: ${config.contentMaxWidth};
  width: 90%;
  min-height: 50vh;

  @media ${device.desktop} {
    max-width: calc(${config.contentMaxWidth} * 1.2);
  }
`

const Container = styled("div")`
  z-index: 0;
  width: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
`

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired
}

export default Post

export const postQuery = graphql`
    query($slug: String!, $authorIds: [String], $nextPost: String, $prevPost: String)
    {
        post: ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
            localFeatureImage{
              childImageSharp {
                fluid(maxWidth: 1440, maxHeight: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }

        prevPost: ghostPost(slug: { eq: $prevPost }) {
            ...GhostPostFields
            localFeatureImage{
              childImageSharp {
                fluid(maxWidth: 1440, maxHeight: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }

        nextPost: ghostPost(slug: { eq: $nextPost }) {
            ...GhostPostFields
            localFeatureImage{
              childImageSharp {
                fluid(maxWidth: 1440, maxHeight: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }

        authors: allGhostAuthor(filter: {slug: {in: $authorIds}} ) {
        edges {
            node {
              ...GhostAuthorFields
              localProfileImage {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 250) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
        }
      }
    }
`
